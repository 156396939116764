import React from 'react';
import { useEffect } from 'react';
import NewEventSec from '../CommComponent/NewEventSec';
import InnerBannerSec from '../CommComponent/InnerBannerSec';
import { Container } from 'react-bootstrap';
import {Helmet} from "react-helmet";

const BlackMoney = () => { 
    useEffect(() => {
        document.body.classList.add('ServicesPage')
        return () => {
            document.body.classList.remove('ServicesPage')
        }
    }, [])
  return (
    <>
        <Helmet>
        <title>Banusekar - Black Money</title>
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Banusekar - Black money & allied economic laws"/>
      </Helmet>
      <InnerBannerSec title="Services"/>

        <section className='SerinListSec'>
          <Container>
            <div className='ComHeadTitles  textalcenter mrgnbtm' data-aos="fade-top" data-aos-duration="1000" >
                <h2>Black Money</h2> 
                {/*<p>The world of black money and allied economic laws encompasses a complex web of regulations aimed at curbing <br/>illicit financial activities and promoting transparency within the economic ecosystem</p>*/}
              </div>
            <div className='SerInListrow'>
              <div className='SerInListcol colone'>
                <div className='SerInListitem' data-aos="fade-down" data-aos-duration="1000" >
                    <div className='SerInListinRow'>
                        <div className='SerInListCol SInLHalfCol'>
                            <img src={require("../../assets/images/blackmoneyImgOne.jpg")} alt="Service List Icon"/>
                        </div>
                        <div className='SerInListCol SInLHalfCol'>
                            <div className='SerInListCont'>
                                <h4>Representation in Appellate Fora</h4>
                                <p>Unearthing of undisclosed foreign income and assets outside India in the hands of a resident and ordinarily resident is the purpose of the Black Money Act. In the context of Black Money and allied economic laws, representation in appellate fora plays an important role of protecting the immediate interests of clients, considering the fact that there is no time limit for invoking the provisions of the Black Money Act and the strict penalties levied under such statutes. A thorough analysis of evidence alongside a reading of financial documents of clients can ensure a strong defense which would yield a favourable outcome while also adhering with the legal regulations. </p>
                            </div>
                        </div>
                        <div className='SerInListCol SInLFullCol'>
                            <div className='SerInListMartop'>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div className='SerInListcol coltwo'>
                <div className='SerInListitem' data-aos="fade-down" data-aos-duration="1000" >
                    <div className='SerInListinRow'>
                        <div className='SerInListCol SInLHalfCol'>
                            <img src={require("../../assets/images/blackmoneyImgTwo.jpg")} alt="Service List Icon"/>
                        </div>
                        <div className='SerInListCol SInLHalfCol'>
                            <div className='SerInListCont'>
                                <h4>Consultancy / Advisory Services</h4>
                                <p>The landscape of Black Money and allied laws is forged with potential pitfalls to individual clients and businesses. Effective consultancy involves in-depth analysis, strategic planning and proactive measures to ensure compliance, mitigation of risks and optimizing financial strategies. It is crucial in such matters to stay abreast of evolving regulations to provide the best solution to clients which suit their requirements without the prospect of uncertainty. </p>
                            </div>
                        </div>
                        <div className='SerInListCol SInLFullCol'>
                            <div className='SerInListMartop'>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div className='SerInListcol colthree'>
                <div className='SerInListitem' data-aos="fade-down" data-aos-duration="1000" >
                    <div className='SerInListinRow'>
                        <div className='SerInListCol SInLHalfCol'>
                            <img src={require("../../assets/images/blackmoneyImgThree.jpg")} alt="Service List Icon"/>
                        </div>
                        <div className='SerInListCol SInLHalfCol'>
                            <div className='SerInListCont'>
                                <h4>Written Opinions</h4>
                                <p>Written opinions serve as invaluable tools for understanding the legal implications of actions of clients. In the context of Black Money and allied economic laws, these opinions would act as clear guidance on compliance, potential risks and strategies for navigating intricate legal terrains. This would further equip clients with rationally articulated insights and help maneuver through difficult problems and to avoid possible levy of penalties. </p>
                            </div>
                        </div>
                        <div className='SerInListCol SInLFullCol'>
                            <div className='SerInListMartop'>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </Container>
      </section>
	  
      <NewEventSec/>
    
    
    </>
  )
}

export default BlackMoney