import React from "react";
import {useEffect} from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./Component/Header/Header";
import Footer from "./Component/Footer/Footer";
import Home from "./Component/Home/Home";
import Aboutus from "./Component/Aboutus/Aboutus";
import Contactus from "./Component/ContactUs/Contactus";
import BanuSpeaks from "./Component/BanuSpeaks/Banuspeaks";
import "./assets/css/style.css";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import ScrollToTop from "./Component/CommComponent/ScrollToTop";
import Services from "./Component/Services/Services";
import BlackMoney from "./Component/Services/BlackMoney";
import Taxation from "./Component/Services/Taxation";
import International from "./Component/Services/International-taxation";
import Benami from "./Component/Services/Benami" ;
import Master from "./Component/Services/master-transfer";
import Thanku from "./Component/Thankyou/Thankyou";
import DisclaimerPopup from "./Component/Popups/DisclaimerPopup"
import PreventionMoney from "./Component/Services/PreventionMoney";

function App() {
  useEffect(() => {
      AOS.init({
        disable: 'mobile',
      });
  }, [])
  return (
    <div className="App">
       <ScrollToTop />
          <Header/>
          <Routes basename="banusekar" forceRefresh={true}>
             <Route exact index path={process.env.PUBLIC_URL + '/'} element={< Home />}></Route>
             <Route path={process.env.PUBLIC_URL + '/aboutus'} element={< Aboutus />}></Route>
             <Route path={process.env.PUBLIC_URL + '/contactus'} element={< Contactus />}></Route>
             <Route path={process.env.PUBLIC_URL + '/services'} element={< Services />}></Route>
             <Route path={process.env.PUBLIC_URL + '/blackmoney'} element={< BlackMoney />}></Route>
             <Route path={process.env.PUBLIC_URL + '/taxation'} element={< Taxation />}></Route>
             <Route path={process.env.PUBLIC_URL + '/international-taxation'} element={< International />}></Route>
             <Route path={process.env.PUBLIC_URL + '/benami'} element={< Benami />}></Route>
             <Route path={process.env.PUBLIC_URL + '/preventionmoney'} element={< PreventionMoney />}></Route>
             <Route path={process.env.PUBLIC_URL + '/master-of-transfer'} element={< Master />}></Route>
             <Route path={process.env.PUBLIC_URL + '/banu-speaks'} element={< BanuSpeaks />}></Route>
             <Route path={process.env.PUBLIC_URL + '/thank-you'} element={< Thanku />}></Route>




          </Routes>
          <Footer />
                       <DisclaimerPopup />

    </div>
  );
}

export default App;
