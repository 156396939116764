import React from 'react';
import { Link } from "react-router-dom";
import { Container,Row,Col, Button } from "react-bootstrap";
import Videopop from '../Popups/Videopop';

const NewEventSec = () => {
    const [videoShow, setModalShow] = React.useState(false);

  return (
    <section className='HNewEventSec'>
        <Container>
            <div className='HNewEventHead ComHeadTitles mrgnbtm'  data-aos="fade-right" data-aos-duration="1000">
                <h2>Insights</h2> 
                <p>Explore the realm of tax and other allied economic laws through  <br/>thought-provoking and insightful blogs and engaging videos.</p>
            </div>

<Videopop show={videoShow}  onHide={() => setModalShow(false)}/>
            <div className='HNewEventCont'>
                <Row>
                    <Col>
                        <div className='hneItems'>
                            <div className='hneItemImg'>
                                <img src={require("../../assets/images/hneweventImg1.jpg")} alt="News Events"/>
                            </div>
                            <div className='hneItemCont' data-aos="fade-up" data-aos-duration="1000">
                                <h3>Articles</h3>
                                <p>Your go-to source for expert perspectives on tax and economic law matters.</p>
                                <Link to="https://banusekar.in/blog/" target='_blank' className='EMoreLink'>Explore</Link>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className='hneItems'>
                            <div className='hneItemImg'>
                                <img src={require("../../assets/images/hneweventImg2.jpg")} alt="News Events"/>
                            </div>
                            <div className='hneItemCont' data-aos="fade-up" data-aos-duration="1000">
                                <h3>Banu Speaks</h3>
                                <p> Unraveling the intricacies of taxation and other allied economic laws.</p>
                                <Link to={`${process.env.PUBLIC_URL}/banu-speaks`} className='EMoreLink'>Explore</Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    </section>
  )
}

export default NewEventSec