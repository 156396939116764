import React from 'react';
import Modal from 'react-bootstrap/Modal';

function  AboutSpeakerPopup(props) {


  return (
 
    <div>

                <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered className='aboutmePopup aboutlegalPopup AbtPopUp'>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              <div className='AboutauthorPopSec'>
                <div className='AboutPopauthorTitles' data-aos="fade-left" data-aos-duration="1000" >
                  <p>With a rich number of about a 1000 presentations and counting, my experience as a speaker has been most enriching. My strong belief in giving back to the profession led me to engage consistently in paper presentations and talks on Direct Taxation and International Taxation at seminars, meetings, workshops and conferences arranged by the ICAI, over and above those conducted by other professional and public bodies, including my participation as a faculty for the Regional Residential Conferences of the Bombay Chartered Accountants Society.</p>
                  <p>During the National Budget Season, analysed the Budget at various fora in the presence of the brilliant minds of finance and taxation professionals from around the world. Moreover, presented my thoughts as a pre budget memorandum personally to the Finance Minister in the year 2023. Have also been invited to Brains Trust Sessions organized by the ICAI. Further, the ICAI forwards the Post Budget Memoranda to the Finance Ministry every year, and this includes some of my thoughts. </p>
                </div>
              </div>
            </Modal.Body>
            </Modal>

    </div>
  )
}

export default AboutSpeakerPopup