import React from 'react';
import Modal from 'react-bootstrap/Modal';


function  TeamSamyukthaPopup(props) {


    return (
   
      <div>
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered className='aboutTeamPopup TeamMemberPopUp'>
            <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                <div className='TeamMemberPopSec'>
                    <div className='TeamMemberTitles' data-aos="fade-left" data-aos-duration="1000" >
                        <h2>Nithya Sankaran</h2>
                        <ul class="ulOverflow">
                            <li>Graduate of commerce from Madras University.</li>
                            <li>Fellow Member of the Institute of Chartered Accountants of India.</li>
                            <li>Holds a bachelor’s degree in Law.</li>
                            <li>On completing CA course in November, 2011 she joined CRISIL Limited as Equity Research Analyst. </li>
                            <li>After being close to two years in employment she joined practice in 2014 and has since been specializing in the field of Direct Taxation. </li>
                            <li>One of the lead member of Direct Tax Team, involved in all the spheres of consultation and litigation. </li>
                            <li>Specialises in the area of Domestic taxation, Black Money and Prevention of Money Laundering.</li>
                            <li>Providing consultation to diverse client base that include MNCs, listed companies and HNIs on issues related to income tax disputes, tax implications on restructuring, re-organisation and independent transactions.</li> 
                            <li>Specialises in rendering advisory services on international taxation issues and actively involved in drafting opinions on the subject of taxation. </li>
                            <li>Assisted the lead partner Mr.T.Banusekar on matters relating to Domestic Taxation, International Taxation and Transfer Pricing both on documentation and arguing matters before Courts and various appellate fora. </li>
                            <li>Actively involved in direct representations before Commissioner of Income Tax (Appeals) and Income Tax Appellate Tribunal. </li>
                            <li>Preparing and representing arbitration cases before the Income Tax Settlement Commission involving analysis, identifying and collation of issues for several years. </li>
                            <li>Articles written and cases handled have been cited in journals and public fora.</li> 

                        </ul>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
  
      </div>
    )
  }
  
  export default TeamSamyukthaPopup