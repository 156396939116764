import React from 'react';
import { useEffect } from 'react';
import NewEventSec from '../CommComponent/NewEventSec';
import InnerBannerSec from '../CommComponent/InnerBannerSec';
import { Container } from 'react-bootstrap';
import {Helmet} from "react-helmet";

const BlackMoney = () => { 
    useEffect(() => {
        document.body.classList.add('ServicesPage')
        return () => {
            document.body.classList.remove('ServicesPage')
        }
    }, [])
  return (
    <>
        <Helmet>
        <title>Best Income Tax Attorney in Chennai | Banusekar.in</title>
        <meta name="theme-color" content="#000000" />
        //<meta name="title" content="Best Income Tax Attorney in Chennai | Banusekar.in"/>		
		<meta name="description" content="We provide income taxation services for clients wherever he is.  Call +91 9363997647 and choose the best income tax attorney in Chennai."/>
        <meta name="keywords" content="Tax lawyer consultation, tax and law, taxation and law, tax lawyer, tax attorney near me, income tax lawyer near me, income tax lawyer near me, tax advocate near me, advocate and tax consultant, tax attorney consultation, taxation lawyers near me, capital gains tax specialist near me, financial advisor capital gains tax"/>		

      </Helmet>
      <InnerBannerSec title="Services"/>

        <section className='SerinListSec'>
          <Container>
            <div className='ComHeadTitles  textalcenter mrgnbtm' data-aos="fade-top" data-aos-duration="1000" >
                <h2>Taxation</h2> 
                {/*<p>The world of taxation is like a complex set of rules with the noble objectives of thwarting nefarious <br/>financial undertakings while fostering a transparent economic landscape.</p>*/}
              </div>
            <div className='SerInListrow'>
              <div className='SerInListcol colone'>
                <div className='SerInListitem' data-aos="fade-down" data-aos-duration="1000" >
                    <div className='SerInListinRow'>
                        <div className='SerInListCol SInLHalfCol'>
                            <img src={require("../../assets/images/taxation-img01.jpg")} alt="Service List Icon"/>
                        </div>
                        <div className='SerInListCol SInLHalfCol'>
                            <div className='SerInListCont'>
                                <h4>Representation in Appellate Fora</h4>
                                <p>The intricate nature of the subject of taxation requires practical knowledge to ensure expertise and this is where experience in representation in appellate fora plays a role. Through years of experience in representation before various appellate fora, one can ensure profound understanding of the subject along with mastering the art of strategic presentation.</p>
                                <p>Representation starts from the basic groundwork, rigorous research of legal precedents and the position of law and analysis of the facts.</p>
                            </div>
                        </div>
                        <div className='SerInListCol SInLFullCol'>
                            <div className='SerInListMartop'>
                                <p>Now that representation before the first appellate authority has become faceless, the art of drafting written submissions has to be persuasive, while at personal hearings before the first appellate authority persuasive written submissions as also actual representation becomes crucial. A scrupulous examination of case facts, drafting of written submissions and tailoring arguments after a thorough analysis of the crucial details can guarantee maximum benefit for clients. In case of representation before higher appellate fora and Courts too, tailor made arguments and research are needed for maximum benefit to clients.</p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div className='SerInListcol coltwo'>
                <div className='SerInListitem' data-aos="fade-down" data-aos-duration="1000" >
                    <div className='SerInListinRow'>
                        <div className='SerInListCol SInLHalfCol'>
                            <img src={require("../../assets/images/taxation-img02.jpg")} alt="Service List Icon"/>
                        </div>
                        <div className='SerInListCol SInLHalfCol'>
                            <div className='SerInListCont'>
                                <h4>Consultancy/Advisory </h4>
                                <p>Providing insightful guidance to individuals and businesses on matters that require clarity is another service usually sought. A comprehensive knowledge that one develops in years of practice would contribute to an informed analysis of clients’ financial situation, business structure and goals by assessing situations that are unique to each of them. Proper guidance on a variety of matters such as enhancing tax efficiency, mitigating risks and taking the right financial decisions is thus ensured by keeping up to date with the latest tax laws and regulations.</p>
                            </div>
                        </div>
                        <div className='SerInListCol SInLFullCol'>
                            <div className='SerInListMartop'>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div className='SerInListcol colthree'>
                <div className='SerInListitem' data-aos="fade-down" data-aos-duration="1000" >
                    <div className='SerInListinRow'>
                        <div className='SerInListCol SInLHalfCol'>
                            <img src={require("../../assets/images/taxation-img03.jpg")} alt="Service List Icon"/>
                        </div>
                        <div className='SerInListCol SInLHalfCol'>
                            <div className='SerInListCont'>
                                <h4>Written Opinion </h4>
                                <p>Complex tax matters often require written opinions based on a detailed understanding of legal nuances, and it forms the basis in several matters. Commitment to precision and thoroughness, would enhance the quality of the outcome, by providing a clear-cut and informed perspective to clients. These opinions would serve as a valuable reference, paving the way to solving challenging tax scenarios and understanding the potential outcomes. </p>
                            </div>
                        </div>
                        <div className='SerInListCol SInLFullCol'>
                            <div className='SerInListMartop'>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </Container>
      </section>
	  
      <NewEventSec/>
    
    
    </>
  )
}

export default BlackMoney