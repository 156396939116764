import React from 'react';
import Modal from 'react-bootstrap/Modal';


function  TeamVijaykumarPopup(props) {


    return (
   
      <div>
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered className='aboutTeamPopup TeamMemberPopUp'>
            <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                <div className='TeamMemberPopSec'>
                    <div className='TeamMemberTitles' data-aos="fade-left" data-aos-duration="1000" >
                        <h2>Vijaykumar</h2>
                        <ul>
                            <li>Graduate of commerce from Bangalore University. </li>
                            <li>A Fellow Member of the Institute of Chartered Accountants of India. </li>
                            <li>Commenced Practice as Chartered Accountant in the year 2011. </li>
                            <li>He specialises in the area of Direct Tax Litigation by representing before the Commissioner of Income Tax (Appeals) and the Income Tax Appellate Tribunal. </li>
                            <li>He also actively practices in Benami Transactions Prohibition. </li>
                        </ul>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
  
      </div>
    )
  }
  
  export default TeamVijaykumarPopup