import React from 'react';
import { useEffect } from 'react';
import NewEventSec from '../CommComponent/NewEventSec';
import InnerBannerSec from '../CommComponent/InnerBannerSec';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

const Services = () => {
  useEffect(() => {
    document.body.classList.add('ServicesPage')
    return () => {
        document.body.classList.remove('ServicesPage')
    }
    }, [])

  return (
    <>
      <Helmet>
        <title>Audit Taxation, Transfer Pricing, Prohibition of Black Money Transaction services in Chennai | Banusekar.in</title>
        <meta name="theme-color" content="#000000" />
        //<meta name="title" content="Audit Taxation, Transfer Pricing, Prohibition of Black Money Transaction services in Chennai | Banusekar.in"/>	
        <meta name="description" content="Banusekar, best financial advisor provide a full range of tax, prohibition of black money transaction and transfer pricing services wherever clients operate."/>
        <meta name="keywords" content="tax pricing services, tax pricing law firm, tax and regulatory services, Benami transactions prohibition act cases, Benami transactions law, audit tax lawyer."/>		
      </Helmet>
      <InnerBannerSec title="Services"/>

      
      <section className='SerListSec'>
          <Container>
            {/*<div className='ComHeadTitles  textalcenter mrgnbtm' data-aos="fade-top" data-aos-duration="1000" >
                <h2>Law chambers of Banusekar </h2>
  </div>*/}
            <div className='SerListrow'>
              <div className='SerListcol colone'>
                <div className='SerListitem' data-aos="fade-right" data-aos-duration="1000" >
                  <img src={require("../../assets/images/SerListIconOne.png")} alt="Service List Icon"/>
                  <h4>Taxation</h4>
                  {/*<p>The world of taxation is like a complex<br/> set of rules with the noble objectives of <br/>thwarting nefarious financial undertakings <br/>while fostering a transparent economic landscape.</p>*/}
                  <Link to={process.env.PUBLIC_URL + '/taxation'} className="EMoreLink">Explore</Link>
                </div>
              </div>
              <div className='SerListcol coltwo'>                  
                <Link to={process.env.PUBLIC_URL + '/international-taxation'}> 
                  <div className='SerListitem' data-aos="fade-left" data-aos-duration="1000" >
                    <img src={require("../../assets/images/SerListIconTwo.png")} alt="Service List Icon"/>
                    <h4>International Taxation</h4>
                    {/*<p>International taxation involves a maze <br/>of regulations intertwined to fulfill the <br/>crucial task of specifically addressing and <br/>favorably resolving all international tax dispute.</p>*/}
                    <Link to={process.env.PUBLIC_URL + '/international-taxation'} className="EMoreLink">Explore</Link>
                  </div>
                </Link>
              </div>
              <div className='SerListcol colthree'> 
                <Link to={process.env.PUBLIC_URL + '/master-of-transfer'} > 
                  <div className='SerListitem' data-aos="fade-right" data-aos-duration="1000" >
                    <img src={require("../../assets/images/MastersofTransferPricingicon.png")} alt="Service List Icon"/>
                    <h4>Transfer Pricing</h4>
                    {/*<p>Transfer Pricing needs profound expertise <br/>dedicated to documentation, audits, and legal <br/>representation to safeguard your compliance <br/>and prosperity and elevate your financial strategy.</p>*/}
                    <Link to={process.env.PUBLIC_URL + '/master-of-transfer'} className="EMoreLink">Explore</Link>
                  </div>
                </Link>
              </div>
              <div className='SerListcol colfour'>
                <Link to={process.env.PUBLIC_URL + '/blackmoney'} > 
                  <div className='SerListitem' data-aos="fade-left" data-aos-duration="1000" >
                    <img src={require("../../assets/images/SerListIconThree.png")} alt="Service List Icon"/>
                    <h4>Black Money</h4>
                    {/*<p>The world of black money and allied economic laws <br/>  encompasses a complex web of regulations aimed <br/>at curbing illicit financial activities and promoting <br/>transparency within the economic ecosystem.</p>*/}
                    <Link to={process.env.PUBLIC_URL + '/blackmoney'} className="EMoreLink">Explore</Link>
                  </div>
                </Link>
              </div>
              <div className='SerListcol colfour'>
                <Link to={process.env.PUBLIC_URL + '/benami'}>
                  <div className='SerListitem' data-aos="fade-right" data-aos-duration="1000" >
                    <img src={require("../../assets/images/SerListIconFour.png")} alt="Service List Icon"/>
                    <h4>Prohibition of Benami <br/>Property Transactions</h4>
                    {/*<p>The world of Benami Transactions related <br/>laws demands a profound grasp and a deep <br/>understanding of property laws, tax rules, and<br/> the complex procedures involved.</p>*/}
                    <Link to={process.env.PUBLIC_URL + '/benami'} className="EMoreLink">Explore</Link>
                  </div>
                </Link>
              </div>
              <div className='SerListcol colfive'>
                <Link to={process.env.PUBLIC_URL + '/preventionmoney'}>
                  <div className='SerListitem' data-aos="fade-left" data-aos-duration="1000" >
                    <img src={require("../../assets/images/SerListIconSix.png")} alt="Service List Icon"/>
                    <h4>Prevention of <br/>Money Laundering</h4>
                    {/*<p>The world of Benami Transactions related <br/>laws demands a profound grasp and a deep <br/>understanding of property laws, tax rules, and<br/> the complex procedures involved.</p>*/}
                    <Link to={process.env.PUBLIC_URL + '/preventionmoney'} className="EMoreLink">Explore</Link>
                  </div>
                </Link>
              </div>
            </div>
          </Container>
      </section>
    
      <NewEventSec/>
    </>
  )
}

export default Services