import React from 'react';
import { useEffect } from 'react';
import NewEventSec from '../CommComponent/NewEventSec';
import InnerBannerSec from '../CommComponent/InnerBannerSec';
import { Container } from 'react-bootstrap';
import {Helmet} from "react-helmet";

const BlackMoney = () => { 
    useEffect(() => {
        document.body.classList.add('ServicesPage')
        return () => {
            document.body.classList.remove('ServicesPage')
        }
    }, [])
  return (
    <>
        <Helmet>
        <title>Benami Property Transactions (Prohibition) Act | Banusekar.in </title>
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Benami transactions Act is an act that prohibits benami transcations in India and government has the right to recover the property."/>
		<meta name="keywords" content="prohibition of benami property transactions act, 2016, prohibition of benami property transactions act find correct statement, prohibition of benami property transactions act pdf, prohibition of benami property transactions act, 1988, benami transaction under transfer of property act, how to identify benami property, benami transaction act 2021, benami transaction act amendment,"/>

      </Helmet>
      <InnerBannerSec title="Services"/>

        <section className='SerinListSec'>
          <Container>
            <div className='ComHeadTitles  textalcenter mrgnbtm' data-aos="fade-top" data-aos-duration="1000" >
                <h2>Prohibition of Benami <br/>Property Transactions</h2> 
                {/*<p>The world of Benami Transactions related laws demands a profound grasp and a deep understanding<br/> of property laws, tax rules, and the complex procedures involved.</p>*/}
              </div>
            <div className='SerInListrow'>
              <div className='SerInListcol colone'>
                <div className='SerInListitem' data-aos="fade-down" data-aos-duration="1000" >
                    <div className='SerInListinRow'>
                        <div className='SerInListCol SInLHalfCol'>
                            <img src={require("../../assets/images/benami-img01.jpg")} alt="Service List Icon"/>
                        </div>
                        <div className='SerInListCol SInLHalfCol'>
                            <div className='SerInListCont'>
                                <h4>Representation in Appellate Fora</h4>
                                <p>Benami transactions involve detailed and often complex arrangements where a property whether movable or immovable is held by one party on behalf of another, often with ulterior motives to evade legal obligations. Cases involving Benami transactions would require determination of a benamidar and a beneficial owner to the transaction.  Handling this complexity and presenting a compelling case on behalf of a person treated as benamidar as well as a person treated as beneficial owner requires a detailed analysis of the transaction treated as Benami, good understanding of property laws, tax regulations and procedural intricacies. </p>
                            </div>
                        </div>
                        <div className='SerInListCol SInLFullCol'>
                            <div className='SerInListMartop'>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div className='SerInListcol coltwo'>
                <div className='SerInListitem' data-aos="fade-down" data-aos-duration="1000" >
                    <div className='SerInListinRow'>
                        <div className='SerInListCol SInLHalfCol'>
                            <img src={require("../../assets/images/benami-img02.jpg")} alt="Service List Icon"/>
                        </div>
                        <div className='SerInListCol SInLHalfCol'>
                            <div className='SerInListCont'>
                                <h4>Consultancy / Advisory </h4>
                                <p>The complex landscape of Benami Transactions Prohibition Act demands proactive strategies to prevent unwitting involvement in such transactions and mitigate risks associated with potential legal entanglements. A strong consultancy and advisory opinion which offers strategic insights and guidance would help in navigating problems of this kind. It would further act as a preventive mechanism before taking decisions related to such financial matters. </p>
                            </div>
                        </div>
                        <div className='SerInListCol SInLFullCol'>
                            <div className='SerInListMartop'>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div className='SerInListcol colthree'>
                <div className='SerInListitem' data-aos="fade-down" data-aos-duration="1000" >
                    <div className='SerInListinRow'>
                        <div className='SerInListCol SInLHalfCol'>
                            <img src={require("../../assets/images/benami-img03.jpg")} alt="Service List Icon"/>
                        </div>
                        <div className='SerInListCol SInLHalfCol'>
                            <div className='SerInListCont'>
                                <h4>Written Opinions</h4>
                                <p>Written opinions serve as important guideposts for Benami transactions, as it provides precise solutions by drawing roadmaps of the current situation of each client. Expert written opinions on these matters are a result of research related to property laws, taxation and the Prohibition of Benami Property Transactions Act. Such solid foundation related to Benami transactions through written opinions would pave way to immediate solutions. </p>
                            </div>
                        </div>
                        <div className='SerInListCol SInLFullCol'>
                            <div className='SerInListMartop'>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </Container>
      </section>
	  
      <NewEventSec/>
    
    
    </>
  )
}

export default BlackMoney