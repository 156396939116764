import React from 'react';
import { useEffect } from 'react';
import InnerBannerSec from '../CommComponent/InnerBannerSec';
import { Container} from "react-bootstrap";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";







const BanuSpeaks = () => {

  useEffect(() => {
    document.body.classList.add('ThankuPage')
    return () => {
        document.body.classList.remove('ThankuPage')
    }
    }, [])





    
  return (
    <div className='AboutusCont'>
    <Helmet>
      <title>Banusekar - Thank You</title>
      <meta name="theme-color" content="#000000" />
      <meta name="description" content="Banusekar - Thank You"/>
    </Helmet>
        <InnerBannerSec title="Thank You"/>
        <section className='thankuintro'>
            <Container>
              <div className='ComSecrow'>
                <div className='BSintrocont'>
                  <div className='ComHeadTitles' data-aos="fade-left" data-aos-duration="1000" >
                      {/* <h5>introduction</h5> */}
                      {/* <h2>A Message of Wisdom</h2>  */}
                      <h3>Thank you for getting in touch. Your inquiry is in good hands.<br/> We will get back to you soon.</h3>
                      <h4>Your trust is our motivation to continue delivering excellence in service.</h4>
                      <Link to={`${process.env.PUBLIC_URL}/`} className='gotohome'>GO TO HOME</Link>

                  </div>
                </div>
              </div>
            </Container>

           
        </section>
   

        {/* <NewEventSec/> */}
    </div>
  )
}

export default BanuSpeaks