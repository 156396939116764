import React from 'react';
import Modal from 'react-bootstrap/Modal';


function  TeamYeshwanthPopup(props) {


    return (
   
      <div>
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered className='aboutTeamPopup TeamMemberPopUp'>
            <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                <div className='TeamMemberPopSec'>
                    <div className='TeamMemberTitles' data-aos="fade-left" data-aos-duration="1000" >
                        <h2>Yeshwanth Kumar</h2>
                        <ul>
                            <li>Graduate of Commerce from Loyola College, Chennai. </li>
                            <li>Associate Member of the Institute of Chartered Accountant of India.</li> 
                            <li>Holds a bachelor’s degree in Law and is enrolled as an Advocate.</li>
                            <li>Specialises in the area of Domestic Taxation, Black Money and Benami Transactions Prohibition. </li>
                            <li>Actively involved in Direct Tax Litigation by representing before Assessing Officer, Commissioner of Income Tax (Appeals) and the Income Tax Appellate Tribunal. </li>
                            <li>Assisted the lead partner Mr.T.Banusekar on matters relating to Domestic Taxation, International Taxation and Transfer Pricing both on documentation and arguing matters before Courts and various appellate fora. </li>
                        </ul>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
  
      </div>
    )
  }
  
  export default TeamYeshwanthPopup