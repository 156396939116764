import React from 'react';
import Modal from 'react-bootstrap/Modal';


function  TeamASharrenPopup(props) {


    return (
   
      <div>
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered className='aboutTeamPopup TeamMemberPopUp'>
            <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                <div className='TeamMemberPopSec'>
                    <div className='TeamMemberTitles' data-aos="fade-left" data-aos-duration="1000" >
                        <h2>Sharren</h2>
                        <ul>
                            <li>Practising advocate based in Chennai. </li>
                            <li>Completed her Law degree from the School of Excellence in Law, Chennai. </li>
                            <li>Commenced her practice in the District Courts and assisted matters relating to partition, land acquisition and other civil cases. </li>
                            <li>Presently specialising in the area of Direct Tax Litigation and represents before Income Tax Appellate Tribunal and HighCourts. </li>
                            <li>Assisted the lead partner Mr.T.Banusekar on arguing matters relating to Domestic Taxation before Courts and various appellate fora. </li>
                        </ul>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
  
      </div>
    )
  }
  
  export default TeamASharrenPopup