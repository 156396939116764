import React from 'react';
import Modal from 'react-bootstrap/Modal';

function  Aboutmepopup(props) {


  return (
 
    <div>

                <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered className='aboutmePopup aboutlegalPopup AbtPopUp'>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
            <div className='AboutPopSec'>
                        <div className='AboutPopTitles' data-aos="fade-left" data-aos-duration="1000" >
                            <h5>introduction</h5>
                            <h2>About Me</h2> 
                            <p>He is a practicing Advocate based in Chennai, Tamilnadu, and hold the qualification as a Chartered Accountant. He also passed the final examination of the Institute of Cost & Works Accountants of India presently known as the Institute of Cost Accountants of India. His Bachelor’s degree in Commerce was from the University of Madras. Having completed his Chartered Accountancy in the year 1994, He built an active practice in Direct Taxation, Benami Transactions Prohibition, Black Money & other allied Economic Laws. </p>
                            <p>He have been offering a range of honorary positions, such as Special Invitee and Chairman of various committees of the ICAI and the Hindustan Chamber of Commerce. He was also a member of the Southern Regional Council of the ICAI for the period from 2001 to 2004. </p>

                        </div>
                    </div>
            </Modal.Body>
            </Modal>

    </div>
  )
}

export default Aboutmepopup