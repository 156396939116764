import React from 'react';
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';

const InnerBannerSec = ({title}) => {
  return (
    <section className="InPageBanSec">
        <Container>
            <div className="inBanCtCntain">
              <div className="inBanContTB">
                  <nav className='breadcrumbnav'>
                    <ul>
                      <li><Link to={`${process.env.PUBLIC_URL}/`}>Home</Link></li>
                      <li className='brLink'>-</li>
                      <li>{title}</li>
                    </ul>
                  </nav>
                  <h1>{title}</h1>
              </div>
            </div>
        </Container>
    </section>
  )
}

export default InnerBannerSec