import React from 'react';
import Modal from 'react-bootstrap/Modal';

function  AboutTechPopup(props) {


  return (
 
    <div>

                <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered className='aboutmePopup aboutlegalPopup AbtPopUp'>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
            <div className='AboutauthorPopSec'>
                        <div className='AboutPopauthorTitles' data-aos="fade-left" data-aos-duration="1000" >
                          <p>In my initial years as a Chartered Accountant, my strong passion for the subject of taxation was fueled by tutoring at the Institute of Chartered Accountants of India, Institute of Company Secretaries of India, the Loyala Institute of Business Administration and Prime Academy, Chennai. Apart from being a visiting faculty at the Institute, was a regular faculty for the training programmes conducted by the Regional Training Institutes of the Income Tax Department and also the Central Training Institute i.e., the National Academy for Direct Taxes, Nagpur. </p>
                          <p>Have always found teaching to be nothing short of a delight and have nurtured some excellent minds in the past, many of whom are in touch with me till date. </p>
                        </div>
                    </div>
            </Modal.Body>
            </Modal>

    </div>
  )
}

export default AboutTechPopup