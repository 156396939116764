import React from 'react';
import Modal from 'react-bootstrap/Modal';


function  TeamSasiPopup(props) {


    return (
   
      <div>
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered className='aboutTeamPopup TeamMemberPopUp'>
            <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                <div className='TeamMemberPopSec'>
                    <div className='TeamMemberTitles' data-aos="fade-left" data-aos-duration="1000" >
                        <h2>Sasi</h2>
                        <ul>
                            <li>A Cost Accountant by qualification with a flair for teaching and 10+ years of experience in finance and accounts of products and services and taxation.</li>
                            <li>Having a passion to study, she enrolled to study Chartered Accountancy at the age of 45.</li>
                            <li>Besides being a visiting faculty on the subject of Costing at the SIRC of Institute of Cost and Management Accountants of India, she has also extended her wings to teach the staff of L&T, Chennai.</li>
                        </ul>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
  
      </div>
    )
  }
  
  export default TeamSasiPopup