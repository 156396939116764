import React from 'react';
import Modal from 'react-bootstrap/Modal';


function  TeamSamyukthaPopup(props) {


    return (
   
      <div>
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered className='aboutTeamPopup TeamMemberPopUp'>
            <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                <div className='TeamMemberPopSec'>
                    <div className='TeamMemberTitles' data-aos="fade-left" data-aos-duration="1000" >
                        <h2>Samyuktha</h2>
                        <ul>
                            <li>Graduate of the Master of Law Course from the University of Cambridge, UK in International Commercial Taxation, Advanced Public Law and other related subjects.</li>
                            <li>Holds a bachelor’s degree in Commerce and Law from SASTRA University, Thanjavur.</li>
                            <li>Enrolled as an Advocate at the Bar Council of Tamil Nadu and Puducherry.</li>
                            <li>Assisted several Advocates / Senior Advocates in matters related to Direct taxation, Transfer Pricing and other Economic Laws and drafted documents in these matters.</li> 
                            <li>Honed her knowledge in these laws by publishing several research papers and articles.</li>
                            <li>Presently appearing before Courts and various appellate fora and also assisting the lead partner Mr.T.Banusekar on arguing matters relating to Domestic Taxation, International Taxation and Transfer Pricing before Courts and various appellate fora.</li>
                        </ul>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
  
      </div>
    )
  }
  
  export default TeamSamyukthaPopup