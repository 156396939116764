import React from 'react';
import { useState } from 'react';
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

function  EnquirePopupFrom(props) {

  const handleMobileKeyPress = e => {
    const mobileValue = e.target.value;
    if (mobileValue.length >= 10) {
      e.preventDefault();
    }
  };
  const [formData, setFormData] = useState({
      name: "",
      email: "",
      mobile: "",
      message: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = e => {
      const { name, value } = e.target;
      setFormData(prevData => ({ ...prevData, [name]: value }));
      setErrors(prevErrors => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
      const validationErrors = {};

      if (!formData.name) {
          validationErrors.name = "Name is required";
      }

      if (!formData.email) {
          validationErrors.email = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          validationErrors.email = "Invalid email format";
      }

      if (!formData.mobile) {
          validationErrors.mobile = "Mobile number is required";
      } else if (!/^\d{10}$/.test(formData.mobile)) {
          validationErrors.mobile = "Invalid mobile number";
      }

      return validationErrors;
  };

  const handleSubmit = async e => {
      e.preventDefault();
      const validationErrors = validateForm();
      if (Object.keys(validationErrors).length === 0) {
          setIsLoading(true);  
          try {
            await axios.post(`https://banusekar.in:3001/send-email`, {
                  name: formData.name,
                  email: formData.email,
                  mobile: formData.mobile,
                  message: formData.message,
                  subject: "Enquiry Form",
              });
              setFormData({
                  name: "",
                  email: "",
                  mobile: "",
                  message: "",
              });
              alert("Email sent successfully");
          } catch (error) {
              alert("An error occurred while sending the email");
          }
          setIsLoading(false);
      } else {
          setErrors(validationErrors);
      }
  };

  return (
 
    <div>
        <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered className='enqnowPopup'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Enquire Now
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form  noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} required/>
                {errors.name && <p className='field-error'>{errors.name}</p>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" name="email" placeholder="Enter email" value={formData.email} onChange={handleChange} required/>
                {errors.email && <p className='field-error'>{errors.email}</p>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPhone">
                <Form.Label>Phone Nome</Form.Label>
                <Form.Control type="tel" name="mobile" placeholder="Phone Number" value={formData.mobile} onChange={handleChange} onKeyPress={handleMobileKeyPress} required/>
                {errors.mobile && <p className='field-error'>{errors.mobile}</p>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPhone">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" name="message" rows={3} placeholder="Message" onChange={handleChange}/>
            </Form.Group>
            <Button variant="primary" type="submit" disabled={isLoading}>{isLoading ? 'Submitting...' : 'Submit'}</Button>
        </Form>
      </Modal.Body>
    </Modal>


    </div>
  )
}

export default EnquirePopupFrom