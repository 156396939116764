import React from 'react';
import { useEffect } from 'react';
import NewEventSec from '../CommComponent/NewEventSec';
import InnerBannerSec from '../CommComponent/InnerBannerSec';
import { Container } from 'react-bootstrap';
import {Helmet} from "react-helmet";

const BlackMoney = () => { 
    useEffect(() => {
        document.body.classList.add('ServicesPage')
        return () => {
            document.body.classList.remove('ServicesPage')
        }
    }, [])
  return (
    <>
        <Helmet>
            <title>International Tax Lawyer in Chennai</title>
            <meta name="theme-color" content="#000000" />
		    <meta name="description" content="Banusekar, the leading International Tax lawyer in Chennai, provides legal services for all your international tax requirements"/>
            <meta name="keywords" content="international tax lawyer consultation, international tax and law, international tax lawyer, international tax attorney near me, income tax lawyer near me, income tax lawyer near me, tax advocate near me, advocate and tax consultant, taxation lawyers near me, capital gains tax specialist near me, financial advisor capital gains tax"/>		
        </Helmet>
      <InnerBannerSec title="Services"/>

        <section className='SerinListSec'>
          <Container>
            <div className='ComHeadTitles  textalcenter mrgnbtm' data-aos="fade-top" data-aos-duration="1000" >
                <h2>International Taxation</h2> 
                {/*<p>International taxation involves a maze of regulations intertwined to fulfill the crucial task of specifically<br/> addressing and favorably resolving all international tax dispute.</p>*/}
              </div>
            <div className='SerInListrow'>
              <div className='SerInListcol colone'>
                <div className='SerInListitem' data-aos="fade-down" data-aos-duration="" >
                    <div className='SerInListinRow'>
                        <div className='SerInListCol SInLHalfCol'>
                            <img src={require("../../assets/images/international-img01.jpg")} alt="Service List Icon"/>
                        </div>
                        <div className='SerInListCol SInLHalfCol'>
                            <div className='SerInListCont'>
                                <h4>Representation in Appellate Fora</h4>
                                <p>International transactions are subject to a complex web of cross-border regulations, tax treaties and evolving jurisprudence. To navigate through this, a comprehensive understanding of double taxation treaties, transfer pricing regulations and other nuances of international law as also facts are required along with the craft of drafting compelling arguments that align with global tax norms and treaties and the experience in representation before various appellate fora.</p>
                            </div>
                        </div>
                        <div className='SerInListCol SInLFullCol'>
                            <div className='SerInListMartop'>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div className='SerInListcol coltwo'>
                <div className='SerInListitem' data-aos="fade-down" data-aos-duration="" >
                    <div className='SerInListinRow'>
                        <div className='SerInListCol SInLHalfCol'>
                            <img src={require("../../assets/images/international-img02.jpg")} alt="Service List Icon"/>
                        </div>
                        <div className='SerInListCol SInLHalfCol'>
                            <div className='SerInListCont'>
                                <h4>Consultancy / Advisory </h4>
                                <p>Navigating the complexities of international taxation demands foresight, astute planning, and in-depth knowledge. A professional consultancy and advisory which is tailored to address each client’s unique cross-border business activities is required to optimize global tax strategies. Insights from an expert on matters such as cross-border mergers and acquisitions, transfer pricing planning and structuring international transactions would lead to making an informed decision about the global business landscape with confidence. </p>
                            </div>
                        </div>
                        <div className='SerInListCol SInLFullCol'>
                            <div className='SerInListMartop'>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div className='SerInListcol colthree'>
                <div className='SerInListitem' data-aos="fade-down" data-aos-duration="" >
                    <div className='SerInListinRow'>
                        <div className='SerInListCol SInLHalfCol'>
                            <img src={require("../../assets/images/international-img03.jpg")} alt="Service List Icon"/>
                        </div>
                        <div className='SerInListCol SInLHalfCol'>
                            <div className='SerInListCont'>
                                <h4>Written Opinion </h4>
                                <p>The nuances of international taxation often require guidance to make better decisions at crucial points in time. A strong written opinion provides clients with researched insights into complex international tax matters. Clarity in matters such as cross-border withholding tax obligations, permanent establishment determinations and interpretation of treaty provisions is required to analyse the problems related to international taxation, and written opinions are often what gives clients such clarity.</p>
                            </div>
                        </div>
                        <div className='SerInListCol SInLFullCol'>
                            <div className='SerInListMartop'>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </Container>
      </section>
	  
      <NewEventSec/>
    
    
    </>
  )
}

export default BlackMoney