import React from 'react';
import Modal from 'react-bootstrap/Modal';

function  AboutLegalpopup(props) {


  return (
 
    <div>

                <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered className='aboutmePopup aboutlegalPopup legalPopup AbtPopUp'>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
            <div className='AboutauthorPopSec'>
                        <div className='AboutPopauthorTitles' data-aos="fade-left" data-aos-duration="1000" >
                          <p>Have built a practice as a Legal Counsel in Direct Taxation, Benami Transactions Prohibition, Black Money and other allied Economic Laws and appeared in a plethora of matters before the Appellate Authorities. In addition to this, my representation before the Income Tax Settlement Commission and the Interim Board for Settlement broadened my horizons in the field of Taxation to a great extent. Have also carefully provided advisory services to my clients in demanding cases. </p>
                          <p>The practice and the challenges that come with it, in complex matters is prized, but it has been most rewarding to build personal relationships with the clients by addressing their needs.</p>
                          <p><b>Some notable cases that have been handled are:</b></p>   
                          <ul class="aboutboxpopup">
                            <li>Ford India Ltd relating to claim of deduction for warranty expenses.</li>
                            <li>Saksoft Ltd before the Special Bench on parity between numerator and denominator in computing deduction u/s.10A.</li>
                            <li>Ford Business Services Centre Pvt Ltd. relating to the benefit under Section 10A.</li>
                            <li>SSI Ltd. relating to taxability of ESOP.</li>
                            <li>Vijaya Productions relating to the accrual of capital gains.</li>
                            <li>Apollo Hospitals Enterprise Ltd relating to taxability of capital gains earned in India on sale of shares of Sri Lankan company.</li>
                            <li>Eastman Exports Global Clothing (P) Ltd relating to taxability of export incentive in the form of MLFPS scrips.</li>
                            <li>Filtrex Technologies Ltd relating to disallowance u/s.40(a)(i).</li>
                            <li>Platex Ltd relating to application of provisions of section 163.</li>
                            <li>South India Corporation Ltd relating to disallowance of interest paid on borrowed funds on account of interest free advances to group concerns. </li>
                            <li>AVM Charities relating to claim of exemption u/s.11.</li>
                            <li>Synergy Maritime India Ltd relating to Transfer Pricing issues.</li>
                            <li>Malayalam Communications Ltd relating to deduction of tax at source u/s.194C or u/s.194J on payments made to artists who participated in reality shows produced for television. </li>
                            <li>Handy Waterbase India Pvt Ltd relating to issue of allowability of deduction u/s.10B even after the introduction of the definition of the term manufacture following the rule of consistency. </li>
                            <li>Refex Industries Ltd relating to allowability of write off of investments in subsidiary and claim of loss on account of corporate guarantee given to subsidiary, as revenue expenditure. </li>
                          </ul>

                        </div>
                    </div>
            </Modal.Body>
            </Modal>

    </div>
  )
}

export default AboutLegalpopup