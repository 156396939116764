import React from 'react';
import Modal from 'react-bootstrap/Modal';


function  TeamSurajPopup(props) {


    return (
   
      <div>
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered className='aboutTeamPopup TeamMemberPopUp'>
            <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                <div className='TeamMemberPopSec'>
                    <div className='TeamMemberTitles' data-aos="fade-left" data-aos-duration="1000" >
                        <h2>Suraj Nahar</h2>
                        <ul>
                            <li>Graduate of Commerce from Loyola College, Chennai.</li>
                            <li>Associate member of the Institute of Chartered Accountants of India. </li>
                            <li>Practising Chartered Accountant since December 2019. </li>
                            <li>Specialises in the field of Direct Taxation and is actively involved in appearing before various Income Tax Authorities including the Assessing Officer, Transfer Pricing Officer, Commissioner of Income Tax (Appeals), Dispute Resolution Panel and Income Tax Appellate Tribunal. </li>
                            <li>He also specialises in rendering advisory services on international taxation issues. </li>
                            <li>Assisted the lead partner Mr.T.Banusekar on matters relating to Domestic Taxation, International Taxation and Transfer Pricing both on documentation and arguing matters before Courts and various appellate fora. </li>
                        </ul>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
  
      </div>
    )
  }
  
  export default TeamSurajPopup