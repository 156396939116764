import React, { useState, useEffect } from 'react';
import './DisclaimerPopup.css';
import Cookies from 'js-cookie';

const DisclaimerPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [userResponse, setUserResponse] = useState(null);


  useEffect(() => {
    const hasAgreed = Cookies.get('hasAgreeds');
    if (!hasAgreed || hasAgreed === 'false') {
      setIsOpen(true);
      document.body.classList.add('popup-open'); // Add the class to the <body> tag

    }
    return () => {
      // Clean up and remove the class when the component unmounts
      document.body.classList.remove('popup-open');
    }
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    Cookies.set('hasAgreeds', 'true', { expires: 1 }); // Expires in 1 day (24 hours)
    document.body.classList.remove('popup-open'); // Add the class to the <body> tag

  };
  const handleDisagree = () => {
    setUserResponse('Disagreed');
    setIsOpen(false);
    document.body.classList.remove('popup-open'); // Add the class to the <body> tag

    // Handle the case when the user disagrees, e.g., redirect them to another page or take appropriate action.
  };

  return (
    isOpen && (
      <div className="disclaimer-popup">
        <div className="popup-content">
          <div className='popup-body'>
          <div className='AdesclimerTitles' data-aos="fade-left" data-aos-duration="1000" >
              <h2>Website Disclaimer</h2>
              <p>As per the rules of the Bar Council of India, lawyers and law firms are not permitted to solicit their work or advertise in any manner. The website is meant solely for the purpose of information and not for the purpose of advertising. </p>
              <p>By clicking on the <b>“I Agree”</b> button, the user agrees and acknowledges as under:</p>
              <ul>
                  <li>There has been no advertisement, personal communication, solicitation, invitation or any other inducement of any sort whatsoever to solicit any work through this website. </li>
                  <li>All material and information (except any statutory instruments or judicial precedents) on this website is my property and no part thereof shall be used, with or without adaptation, without the express prior written consent or approval from me</li>
                  <li>The user(s) wishes to gain more information about me for his / her / their own information and use </li>
                  <li>All information about me on this website is being provided to the user(s) only on his / her / their specific request and any information obtained or materials downloaded from this website is completely at the violation of user(s); and any transmission, receipt or use of this site would not create any lawyer-client relationship</li>
                  <li>The information provided on this website is solely available at the request of the user(s) for informational purposes only. It should not be interpreted as a soliciting or advertisement. </li>
                  <li>I assume no liability for the interpretation and / or use of the information available on this website, neither does it offer a warranty of any kind, nor express or implicit authorization of the content included in www.banusekar.in not even through a hyperlink, without express and written consent from www.banusekar.in </li> 
                  <li>The content available on this website does not constitute, and shall not be construed, as legal advice or a substitute for legal advice</li>
                  <li>I am not liable for any consequence of any action taken by the user(s) relying on material / information provided on this website or through any external links thereon </li>
                  <li>This website is a resource for informational purposes only and though intended, is not promised or guaranteed, to be complete or updated. I do not warrant that the information contained on this website is accurate or complete, and hereby disclaim any and all liability to any person for any loss or damage caused by errors or omissions, whether such errors or omissions result from negligence, accidents or any other cause </li>
              </ul>                       
          </div>
          <button onClick={handleClose}>I Agree</button>
          <button onClick={handleDisagree}>Disagree</button>
          </div>

        </div>
      </div>
    )
  );
};

export default DisclaimerPopup;
