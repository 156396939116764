import React from 'react';
import Modal from 'react-bootstrap/Modal';

function  Aboutauthorpopup(props) {


  return (
 
    <div>

                <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered className='aboutmePopup AbtPopUp'>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
            <div className='AboutauthorPopSec'>
                        <div className='AboutPopauthorTitles' data-aos="fade-left" data-aos-duration="1000" >
                          <p>The adoration for the subject tied with my ardent knowledge gained through years of practice inspired me to author books on the nuances of Taxation:</p>
                           <ul class="aboutboxpopup">
                                <li>Hand Book on Assessment Procedure under the Income Tax Act, 1961</li>
                                <li>Search Assessment under the Income Tax Act</li>
                           </ul>
                           <p>Authored a column “Tax Talk” in the Hindu Business Line for about a decade and contributed articles on Direct Tax and International Taxation to several tax journals such as Consolidated Commercial Digest (Published by the Publishers of ITR), Taxman, Tax Referencer and Income Tax Review (Published by the Chamber of Income Tax Consultants, Mumbai).</p>
                        </div>
                    </div>
            </Modal.Body>
            </Modal>

    </div>
  )
}

export default Aboutauthorpopup