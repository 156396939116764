import React from 'react';
import Modal from 'react-bootstrap/Modal';

function  Videopop01(props) {

  return (
 
    <div>
        <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered className='videopopup'    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
      <iframe width="100%" height="400" src="https://www.youtube.com/embed/l5iru1Xx4Qk?si=dEf1uR8YyjG_N01C" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>      </Modal.Body>
    </Modal>


    </div>
  )
}

export default Videopop01