import React from 'react';
import Modal from 'react-bootstrap/Modal';


function  TeamSumithraPopup(props) {


    return (
   
      <div>
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered className='aboutTeamPopup TeamMemberPopUp'>
            <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                <div className='TeamMemberPopSec'>
                  <div className='TeamMemberTitles' data-aos="fade-left" data-aos-duration="1000" >
                      <h2>Sumithra</h2>
                      <ul>
                        <li>Graduate of Commerce from Meenakshi College, Chennai. </li>
                        <li>Fellow Member of the Institute of Company Secretaries of India. </li>
                        <li>Has also passed the final examination of the Institute of Cost & Works Accountants of India presently known as the Institute of Cost Accountants of India. </li>
                        <li>Commenced employment in a firm of Chartered Accountants in the year 1997. </li>
                        <li>Held certificate of practice as Company Secretary from the year 2002 to 2020. </li>
                        <li>Specialises in the area of Direct Taxation, Benami Transactions Prohibition and Black Money </li>
                        <li>Actively involved in drafting opinions for clients on the above referred subjects. </li>
                        <li>Assisted the lead partner Mr.T.Banusekar on arguing matters relating to Domestic Taxation before Courts and various appellate fora. </li>
                    </ul>
                  </div>
                </div>
            </Modal.Body>
        </Modal>
      </div>
    )
  }
  
  export default TeamSumithraPopup