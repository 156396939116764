import React from 'react';
import { useEffect } from 'react';
import InnerBannerSec from '../CommComponent/InnerBannerSec';
import NewEventSec from '../CommComponent/NewEventSec';
import { Container } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Aboutmepopup from '../Popups/AboutmePopup';
import AboutauthorPopup from '../Popups/AboutauthorPopup';
import AboutTechPopup from '../Popups/AboutTechPopup';
import AboutSpeakerPopup from '../Popups/AboutSpakerPopup';
import AboutLegalpopup from '../Popups/AboutLegalPopup';
import TeamSumithraPopup from '../Popups/TeamSumithraPopup';
import TeamSurajPopup from '../Popups/TeamSurajPopup';
import TeamYeshwanthPopup from '../Popups/TeamYeshwanthPopup';
import TeamSasiPopup from '../Popups/TeamSasiPopup';
import TeamSamyukthaPopup from '../Popups/TeamSamyukthaPopup';
import TeamNithyaPopup from '../Popups/TeamNithyaPopup';
import TeamDarshanBothraPopup from '../Popups/TeamDarshanBothraPopup';
import TeamASharrenPopup from '../Popups/TeamASharrenPopup';
import TeamVijaykumarPopup from '../Popups/TeamVijaykumarPopup';

import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";


const Aboutus = () => {

  useEffect(() => {
    document.body.classList.add('AboutusPage')
    return () => {
        document.body.classList.remove('AboutusPage')
    }
    }, [])
    const [aboutmeshow, setModalShow1] = React.useState(false);
    const [aboutauthormeshow, AtBoxOne] = React.useState(false);
    const [abouttechpopup, AtBoxTwo] = React.useState(false);
    const [aboutlegalpopup, AtBoxThree] = React.useState(false);
    const [aboutspeakerpopup, AtBoxFour] = React.useState(false);
    const [SumithraShow, setSumithraShow] = React.useState(false);
    const [SurajShow, setSurajShow] = React.useState(false);
    const [YeshwanthShow, setYeshwanthShow] = React.useState(false);
    const [SasiShow, setSasiShow] = React.useState(false);
    const [SamyukthaShow, setSamyukthaShow] = React.useState(false);
    const [NithyaShow, setNithyaShow] = React.useState(false);
    const [DarshanBothraShow, setDarshanBothraShow] = React.useState(false);
    const [ASharrenShow, setASharrenShow] = React.useState(false);
    const [VijaykumarShow, setVijaykumarShow] = React.useState(false);
    


    
  return (
    <div className='AboutusCont'>
    <Helmet>
      <title>Banusekar - About Us</title>
      <meta name="theme-color" content="#000000" />
      <meta name="description" content="Banusekar - About Us"/>
    </Helmet>
        <InnerBannerSec title="About Us"/>
        <section className='AboutwhowrSec'>
            <div className='whowrSBgImg'>
                <img src={require ("../../assets/images/WhowerBgImg.jpg")} alt="Banusekar Enquire Now"/>
            </div>
            <div class="whowertbbox">
              <Container>
                <div class="whowertb">
                    <div className='ComHeadTitles' data-aos="fade-top" data-aos-duration="1000" >
                      {/* <h5>About Us</h5>  */}
                      <h3>Welcome to <br/>The Law Chambers <br/>of Banusekar</h3> 
                      <p>At The Law Chambers of Banusekar, we bring in decades of specialized expertise in Direct Taxation as also on matters relating to the Benami Transactions Prohibition, Black Money, Money Laundering and other allied Economic Laws. Led by Advocate Banusekar, who is also qualified as a Chartered Accountant and Cost Accountant the firm has deep domain knowledge and practical approach to resolving complex tax disputes and on matters related to allied economic laws.</p>

                      <p>Our practice focuses on providing strategic, high-level legal advice tailored to Corporations and High Net worth Individuals. Whether you are navigating the complexities of the Indian Income Tax Act, tackling transfer pricing issues, seeking advice on cross-border tax implications or on matters relating to allied economic laws, our team has the legal acumen and experience to guide you every step of the way.</p>

                      <p>At The Law Chambers of Banusekar, we take pride in our personalized, client-focused approach. We believe in <br/>delivering timely and effective solutions to meet your legal and tax needs, ensuring that your business interests <br/>are protected and your legal risks are minimized. As a trusted advisor to numerous businesses and High Net <br/>worth Individuals, we strive to uphold our legacy of integrity, diligence, and commitment to excellence.</p>

                      <p>Our team consists of professionals who assist clients through every phase of the legal and tax process as <br/>also on allied economic laws, from advisory and planning to litigation and dispute resolution.</p>

                    
                    </div>
                </div>
               </Container>
            </div>
        </section>
        <section className='AboutIntroSec'>
            <Container>
              <div className='ComSecrow'>
                <div className='auintrocont'>
                  <div className='ComHeadTitles' data-aos="fade-top" data-aos-duration="1000" >
                      <h2>Meet the Founder</h2> 
                      <p>Practicing as an Advocate based in Chennai, Tamilnadu, <br/>and also a qualified Chartered Accountant</p>
                  </div>
                </div>
                <div className='auintroimg mobile'><img src={require("../../assets/images/aboutBSintroImg.png")} alt="Banusekar"  data-aos="fade" data-aos-duration="1000"/></div>
                    <div className='AboutItemInCont'>
                        <div className='AbtBox  AtBoxOne'>
                            <img src={require("../../assets/images/ban-lc-icon.svg").default} alt="Banusekar Banner Img"/>
                            <h3>Legal Counsel</h3>
                            <p>Represented clients in a variety <br/>of challenging matters before Appellate<br/> Authorities and further ensured clarity to <br/>the clients through advisory services<br/> and opinions.</p>
                            <Button  className="expolink"  onClick={() => AtBoxThree(true)}><span>Explore</span></Button>
                        </div>
                        <div className='AbtBox AtBoxTwo'>
                            <img src={require("../../assets/images/ban-author-icon.svg").default} alt="Banusekar Banner Img"/>
                            <h3>Author </h3>
                            <p>The passion to write and the importance<br/> placed on keeping up to speed on the subject<br/> of taxation and allied laws has always been <br/>a motivation to author various books, <br/>columns and pieces.</p>
                            <Button  className="expolink"  onClick={() => AtBoxOne(true)}><span>Explore</span></Button>
                        </div>
                        <div className='AbtBox AtBoxThree'>
                            <img src={require("../../assets/images/ban-teaching-icon.svg").default} alt="Banusekar Banner Img"/>
                            <h3>Teacher </h3>
                            <p>Nurturing the minds of students on <br/>taxation has always brought me nothing short <br/>of contentment, and many of my students<br/> have grown to be promising Chartered <br/>Accountants.</p>
                            <Button  className="expolink"  onClick={() => AtBoxTwo(true)}><span>Explore</span></Button>
                        </div>
                        <div className='AbtBox AtBoxFour'>
                            <img src={require("../../assets/images/ban-speaker-icon.svg").default} alt="Banusekar Banner Img"/>
                            <h3>Speaker </h3>
                            <p>Have been invited to several<br/> conferences, seminars, meetings, workshops<br/> with like minds by a plethora of professional<br/> bodies including the ICAI and also the Training<br/> Institute of the Income Tax Department.</p>
                            <Button  className="expolink"  onClick={() => AtBoxFour(true)}><span>Explore</span></Button>
                        </div>
                        <Aboutmepopup show={aboutmeshow} onHide={() => setModalShow1(false)} />
                        <AboutauthorPopup show={aboutauthormeshow} onHide={() => AtBoxOne(false)} />
                        <AboutTechPopup show={abouttechpopup} onHide={() => AtBoxTwo(false)} />
                        <AboutLegalpopup show={aboutlegalpopup} onHide={() => AtBoxThree(false)} />
                        <AboutSpeakerPopup show={aboutspeakerpopup} onHide={() => AtBoxFour(false)} />
                    </div>
                <div className='auintroimg desktop'><img src={require("../../assets/images/aboutBSintroImg.png")} alt="Banusekar"  data-aos="fade" data-aos-duration="1000"/></div>

              </div>
            </Container>
        </section>
        <section className='AboutAwardSec'>
            <Container>
              <div className='ComHeadTitles  textalcenter mrgnbtm' data-aos="fade-top" data-aos-duration="1000" >
                  <h2>Our Team</h2> 
                  <p>A Dedicated Team of Skilled and Experienced Legal Professionals.</p>
               </div>
              <div className='ComSecrow'>
                <div className='awardcol btmspace'>
                  <div className='teamsboxss'>
                    <div className='awarditem'><img src={require("../../assets/images/teams/TeamSumithraImg.png")} alt="Awards" data-aos="fade-bottom" data-aos-duration="1000" /></div>
                    <h4>Sumithra</h4>
                    <p className="enquirenpara"><Link className="enquirenLink" href="#" onClick={() => setSumithraShow(true)}>Read More</Link></p>
                  </div>
                </div>
                <div className='awardcol'>
                  <div className='teamsboxss'>
                    <div className='awarditem'><img src={require("../../assets/images/teams/TeamVijaykumarImg.png")} alt="Awards" data-aos="fade-bottom" data-aos-duration="1000"/></div>
                    <h4>Vijaykumar</h4>
                    <p className="enquirenpara"><Link className="enquirenLink" href="#" onClick={() => setVijaykumarShow(true)}>Read More</Link></p>    
                  </div>
                </div>
                <div className='awardcol'>
                  <div className='teamsboxss'>
                    <div className='awarditem'><img src={require("../../assets/images/teams/TeamNithyaImg.png")} alt="Awards" data-aos="fade-bottom" data-aos-duration="1000"/></div>
                    <h4>Nithya Sankaran</h4>
                    <p className="enquirenpara"><Link className="enquirenLink" href="#" onClick={() => setNithyaShow(true)}>Read More</Link></p>    
                  </div>
                </div>
                <div className='awardcol btmspace'>
                  <div className='teamsboxss'>
                    <div className='awarditem'><img src={require("../../assets/images/teams/TeamSasiImg.png")} alt="Awards" data-aos="fade-bottom" data-aos-duration="1000"/></div>
                    <h4>Sasi</h4>
                    <p className="enquirenpara"><Link className="enquirenLink" href="#" onClick={() => setSasiShow(true)}>Read More</Link></p>    
                  </div>
                </div>
                <div className='awardcol btmspace'>
                  <div className='teamsboxss'>
                    <div className='awarditem'><img src={require("../../assets/images/teams/TeamYeshwanthImg.png")} alt="Awards" data-aos="fade-bottom" data-aos-duration="1000"/></div>
                    <h4>Yeshwanth Kumar</h4>
                    <p className="enquirenpara"><Link className="enquirenLink" href="#" onClick={() => setYeshwanthShow(true)}>Read More</Link></p>    
                  </div>
                </div>
                <div className='awardcol btmspace'>
                  <div className='teamsboxss'>
                    <div className='awarditem'><img src={require("../../assets/images/teams/TeamSurajImg.png")} alt="Awards" data-aos="fade-bottom" data-aos-duration="1000"/></div>
                    <h4>Suraj Nahar</h4>
                    <p className="enquirenpara"><Link className="enquirenLink" href="#" onClick={() => setSurajShow(true)}>Read More</Link></p>    
                  </div>
                </div>
                <div className='awardcol'>
                  <div className='teamsboxss'>
                    <div className='awarditem'><img src={require("../../assets/images/teams/TeamDarshanBothraImg.png")} alt="Awards" data-aos="fade-bottom" data-aos-duration="1000"/></div>
                    <h4>Darshan Bothra</h4>
                    <p className="enquirenpara"><Link className="enquirenLink" href="#" onClick={() => setDarshanBothraShow(true)}>Read More</Link></p>    
                  </div>
                </div>
                <div className='awardcol'>
                  <div className='teamsboxss'>
                    <div className='awarditem'><img src={require("../../assets/images/teams/TeamASharrenImg.png")} alt="Awards" data-aos="fade-bottom" data-aos-duration="1000"/></div>
                    <h4>Sharren</h4>
                    <p className="enquirenpara"><Link className="enquirenLink" href="#" onClick={() => setASharrenShow(true)}>Read More</Link></p>    
                  </div>
                </div>
                <div className='awardcol btmspace'>
                  <div className='teamsboxss'>
                    <div className='awarditem'><img src={require("../../assets/images/teams/TeamSamyuktha.png")} alt="Awards" data-aos="fade-bottom" data-aos-duration="1000"/></div>
                    <h4>Samyuktha</h4>
                    <p className="enquirenpara"><Link className="enquirenLink" href="#" onClick={() => setSamyukthaShow(true)}>Read More</Link></p>    
                  </div>
                </div>
              </div>
            </Container>
        </section>

        <NewEventSec/>
                               
        <TeamSumithraPopup  show={SumithraShow} onHide={() => setSumithraShow(false)} />                     
        <TeamSurajPopup show={SurajShow} onHide={() => setSurajShow(false)}/>
        <TeamYeshwanthPopup show={YeshwanthShow} onHide={() => setYeshwanthShow(false)}/>
        <TeamSasiPopup show={SasiShow} onHide={() => setSasiShow(false)}/>
        <TeamSamyukthaPopup show={SamyukthaShow} onHide={() => setSamyukthaShow(false)}/>
        <TeamNithyaPopup show={NithyaShow} onHide={() => setNithyaShow(false)}/>
        <TeamDarshanBothraPopup show={DarshanBothraShow} onHide={() => setDarshanBothraShow(false)}/>
        <TeamASharrenPopup show={ASharrenShow} onHide={() => setASharrenShow(false)}/>
        <TeamVijaykumarPopup show={VijaykumarShow} onHide={() => setVijaykumarShow(false)}/>
    </div>
  )
}

export default Aboutus