import React from 'react';
import Modal from 'react-bootstrap/Modal';


function  TeamDarshanBothraPopup(props) {


    return (
   
      <div>
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered className='aboutTeamPopup TeamMemberPopUp'>
            <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                <div className='TeamMemberPopSec'>
                    <div className='TeamMemberTitles' data-aos="fade-left" data-aos-duration="1000" >
                        <h2>Darshan Bothra</h2>
                        <ul>
                            <li>Graduate of Commerce from Madras University, Chennai.</li>
                            <li>Has passed the CA Final exams conducted by the Institute of Chartered Accountants of India.</li> 
                            <li>He specialises in the area of Domestic and International Taxation, Transfer Pricing, Benami Transactions Prohibition and Black Money.</li>
                            <li>He is actively involved in drafting opinions on the subject of taxation.</li>
                            <li>Assisted the lead partner Mr.T.Banusekar on matters relating to Domestic Taxation, International Taxation and Transfer Pricing both on documentation and arguing matters before Courts and various appellate fora.</li>
                        </ul>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
  
      </div>
    )
  }
  
  export default TeamDarshanBothraPopup