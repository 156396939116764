import React from 'react';
import { useState } from 'react';
import { Form, Button  } from 'react-bootstrap';
import axios from "axios";

const ContactusForm = () => {

    const handleMobileKeyPress = e => {
        const mobileValue = e.target.value;
        if (mobileValue.length >= 10) {
          e.preventDefault();
        }
      };
      const [formData, setFormData] = useState({
          name: "",
          email: "",
          mobile: "",
          city: "",
          message: "",
      });
      const [errors, setErrors] = useState({});
      const [isLoading, setIsLoading] = useState(false);
    
      const handleChange = e => {
          const { name, value } = e.target;
          setFormData(prevData => ({ ...prevData, [name]: value }));
          setErrors(prevErrors => ({ ...prevErrors, [name]: "" }));
      };
    
      const validateForm = () => {
          const validationErrors = {};
    
          if (!formData.name) {
              validationErrors.name = "Name is required";
          }
    
          if (!formData.email) {
              validationErrors.email = "Email is required";
          } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
              validationErrors.email = "Invalid email format";
          }
    
          if (!formData.mobile) {
              validationErrors.mobile = "Mobile number is required";
          } else if (!/^\d{10}$/.test(formData.mobile)) {
              validationErrors.mobile = "Invalid mobile number";
          }

          if (!formData.city) {
            validationErrors.city = "City is required";
          }
    
          return validationErrors;
      };
    
      const handleSubmit = async e => {
          e.preventDefault();
          const validationErrors = validateForm();
          if (Object.keys(validationErrors).length === 0) {
              setIsLoading(true);  
              try {
                await axios.post(`https://banusekar.in:3001/send-email`, {
                      name: formData.name,
                      email: formData.email,
                      mobile: formData.mobile,
                      mobile: formData.city,
                      message: formData.message,
                      subject: "Contact Us Form",
                  });
                  setFormData({
                      name: "",
                      email: "",
                      mobile: "",
                      city: "",
                      message: "",
                  });
                  alert("Email sent successfully");
              } catch (error) {
                  alert("An error occurred while sending the email");
              }
              setIsLoading(false);
          } else {
              setErrors(validationErrors);
          }
      };

  return (
   <Form noValidate onSubmit={handleSubmit} className="ContusFrom">
    <div className="CusfrmIncotn">
     <div className="CotfrmRow">
      <div className="formcol halfcol">
      <Form.Control type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} required/>
        {errors.name && <p className='field-error'>{errors.name}</p>}
      </div>
      <div className="formcol halfcol">
      <Form.Control type="email" name="email" placeholder="Enter email" value={formData.email} onChange={handleChange} required/>
                {errors.email && <p className='field-error'>{errors.email}</p>}
      </div>
     </div>
     <div className="CotfrmRow">
      <div className="formcol halfcol">
      <Form.Control type="tel" name="mobile" placeholder="Phone Number" value={formData.mobile} onChange={handleChange} onKeyPress={handleMobileKeyPress} required/>
                {errors.mobile && <p className='field-error'>{errors.mobile}</p>}
      </div>
      <div className="formcol halfcol">
       <Form.Control type="text" name="city" placeholder="City" value={formData.city} onChange={handleChange} required />
       {errors.city && <p className='field-error'>{errors.city}</p>}
      </div>
     </div>
     <div className="CotfrmRow">
      <div className="formcol fullcol">
       <Form.Control type="text" name="message" placeholder="Message" onChange={handleChange}/>
      </div>
     </div>
     <div className="CotfrmRow LastRow">
      <div className="formcol fullcol">
      <Button variant="primary" type="submit" disabled={isLoading}>{isLoading ? 'Submitting...' : 'Submit'}</Button>
      </div>
     </div>
    </div>
   </Form>
  );
}

export default ContactusForm